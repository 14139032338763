import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from "react"
// import { Chart } from "../components/Chart";
// import { CChart } from "../node_modules/@coreui/chartjs";
import { CChart } from "@coreui/react-chartjs";
const headers = {'Content-Type':'application/json',
                    'Access-Control-Allow-Origin':'*',
                    'Access-Control-Allow-Methods':'POST,PATCH,OPTIONS'}
function App() {

  useEffect(() => {
    const fetchDomains = async () => {
      const res = await fetch("https://my-project.mfguo.workers.dev/popular-domains", {method: 'GET', headers: headers})
      const data = await res.json()
      console.log(data)
      setChartData({
        labels: data.data.map((crypto) => crypto.name),
        datasets: [
          {
            label: "",
            data: data.data.map((crypto) => crypto.priceUsd),
            backgroundColor: [
              "#ffbb11",
              "#ecf0f1",
              "#50AF95",
              "#f3ba2f",
              "#2a71d0"
            ]
          }
        ]
      });
    };
    fetchDomains()
  }, []);

  const [chartData, setChartData] = useState({})

  return (
    <div className="App">
      <CChart chartData={chartData} />
    </div>
  );
}

export default App;
